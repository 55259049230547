import { SessionType } from '@/types';

export function redirectIfNoTenant(session: SessionType | null) {
  if (!session) {
    return;
  }
  if (session.user?.uid && !session?.user?.loginTenantUid) {
    const encodeBackUrl = encodeURIComponent(window.location.href);
    location.href = '/#/changeTenant?callbackUrl=' + encodeBackUrl;
    return;
  }
}

export function navigateHome() {
  window.location.href = '/workspace#/dashboard/home';
}
