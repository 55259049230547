import { FC } from 'react';
import { Button, Result } from 'antd';
import { navigateHome } from '@/libs/fe/navigate';
import { ErrorType } from '@/types';

interface Props {
  error: ErrorType | null;
}

const ErrorCard: FC<Props> = ({ error }) => {
  return (
    <Result
      status="500"
      title={error?.message}
      subTitle="请联系管理员处理"
      extra={
        <Button type="primary" onClick={navigateHome}>
          返回工作台
        </Button>
      }
    />
  );
};

export default ErrorCard;
