import { ErrorType } from '@/types';
import ErrorCard from '@/components/ErrorCard';
import React from 'react';

const Error404Page = () => {
  const error: ErrorType = {
    message: '您访问的网址不正确，请联系网址的来源方或您团队的管理员进行处理~'
  };
  return <ErrorCard error={error} />;
};

export default Error404Page;
